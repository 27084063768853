body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: auto!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  line-height: 1.5;
  color: #24292e;
  background-color: #fff;
}

.app {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

input.error {
  border-color: red;
}


.input-feedback {
  color: red;
  height: 5px;
  margin-top: -12px;
}

section {
  margin-top: 69px;
  min-height: calc(100vh - 69px);
}

footer {
  position: fixed;
  bottom: 0px;
  width: 100%;
}

.ant-table-tbody>tr>td, .ant-table-thead>tr>th {
  padding: 10px 16px;
}